@font-face {
  font-family: "CCWildWordsRoman";
  src: url("./fonts/CCWildWords.ttf");
}

@font-face {
  font-family: "Haettenschweiler";
  src: url("./fonts/Haettenschweiler.ttf");
}


@font-face {
  font-family: "Bebas";
  src: url("./fonts/BebasNeueRegular.ttf");
}

* {
  font-family: 'Bebas';
  box-sizing: content-box;
  color: white;
  line-height: 1.3em;
  letter-spacing: 4px;
}

html, body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100%;
}

body {
  display: flex;
  margin: 0;
}

html {
  font-size: 100%;
}

@media (max-width: 720px) {
  html {
    font-size: 65%;
  }
}

body {
  background: black;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3 {
  font-family: 'Haettenschweiler', Impact, sans-serif;
  letter-spacing: 4px;
  font-size: 32em;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  font-weight: 500;
}

@media screen and (max-width: 720px) {
  h1, h2, h3 {
    text-align: center;
  }
}

h1 {
  font-size: 7rem;
}

h2 {
  font-size: 4.35rem;
}

h3 {
  font-size: 3.5rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 2rem;
}

h6 {
  font-size: 1.5rem;
}

p {
  font-size: 1.1rem;
  margin: 4px 0px;
}